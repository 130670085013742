(function () {
    angular.module('informaApp')
        .service('ActivityTypes', ActivityTypesService);

    function ActivityTypesService() {
        return {
            login: 'login',
            logout: 'logout'
        };
    }
})();
